import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import moment from 'moment'
import ManagementDashboard from '@/pages/ManagementDashboard/ManagementDashboard.vue'

const { user } = getModule(SystemtModule)

const rootCompanyId = process.env.VUE_APP_ROOT_COMPANY_ID

let report_module: any = [
  {
    title: 'Reports',
    icon: 'chart-line',
    link: () => {
      if (user && user.can('report', 'edit')) {
        return '/app/reports'
      }
      return '/app/report'
    },
    index: 'reports',
    acl: {
      model: 'report',
      action: 'view',
    },
  },
]

if (
  user
  && (user.company_id === rootCompanyId
    || user.id == '9546f8d0-db32-4c5c-ba3a-b83f74247503'
    || user.id == '9562c6e6-cc04-4df7-8a67-8dd482add712')
) {
  report_module = []

  report_module.push({
    title: 'Reports',
    icon: 'chart-line',
    link: () => {
      if (user && user.can('report', 'edit')) {
        return '/app/report-builders'
      }
      return '/app/report-builder'
    },
    index: 'report-builder',
    acl: {
      model: 'report',
      action: 'view',
    },
  })
}

let tasks = [
  {
    title: 'Tasks',
    icon: 'tasks',
    link: '/app/tasks',
    index: 'tasks',
    acl: {
      model: 'task',
      action: 'view',
    },
  },
]

const management = []

if (user && user.canOptions('management_dashboard', 'marketing_workload')) {
  management.push({
    title: 'Management View V2',
    icon: 'globe-americas',
    link: '/app/management-dashboard-v2',
    index: 'management-dashboard-v2',
    acl: {
      model: 'management_dashboard',
      action: 'view',
    },
  })
}

// Sales user should not have access to tasks module
if (user?.type == 'sales') {
  tasks = []
}

const now = moment()
export default [
  {
    title: 'Management View',
    icon: 'globe-americas',
    link: '/app/management-dashboard',
    index: 'management-dashboard',
    acl: {
      model: 'management_dashboard',
      action: 'view',
    },
  },
  ...management,
  {
    title: 'Insights',
    icon: 'dashboard',
    link: '/app/insights',
    index: 'insights',
    acl: {
      model: 'insights',
      action: 'view',
    },
  },
  ...tasks,
  ...report_module,
  {
    title: 'Foundry CRM',
    icon: 'briefcase',
    index: 'crm',
    children: [
      {
        title: 'Opportunities',
        icon: 'las la-handshake',
        link: '/app/opportunities',
        index: 'opportunities',
        acl: {
          model: 'opportunity',
          action: 'view',
        },
      },
      {
        title: 'Business Entities',
        icon: 'building',
        link: '/app/companies',
        index: 'companies',
        acl: {
          model: 'company',
          action: 'view',
        },
      },
      {
        title: 'Business Contacts',
        icon: 'address-card',
        link: '/app/company-contacts',
        index: 'company-contacts',
        acl: {
          model: 'company',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Planning',
    icon: 'calendar-week',
    index: 'sales',
    children: [
      {
        title: 'Financial Planning',
        icon: 'las la-bullseye',
        link: '/app/goals',
        index: 'financial-planning',
        acl: {
          model: 'financial_plan',
          action: 'view',
        },
      },
      {
        title: 'Calendar',
        icon: 'las la-calendar',
        link: '/app/calendar',
        index: 'calendar',
        acl: {
          model: 'calendar',
          action: 'view',
        },
      },
      {
        title: 'Media Packages',
        icon: 'box',
        link: '/app/sales/media_packages',
        index: 'media_packages',
        acl: {
          model: 'media_package',
          action: 'view',
        },
      },
      {
        title: 'Dynamic Rate',
        icon: 'magic',
        link: '/app/sales/dynamic_rates',
        index: 'dynamic_rates',
        acl: {
          model: 'dynamic_rate',
          action: 'view',
        },
      },
      {
        title: 'Max Avails Plan',
        icon: 'las la-book-open',
        link: '/app/sales/pre-plans',
        index: 'preplans',
        acl: {
          model: 'max_avails_plan',
          action: 'view',
        },
      },
      {
        title: 'Media Plan',
        icon: 'project-diagram',
        link: '/app/sales/media_plans',
        index: 'media_plan',
        acl: {
          model: 'media_plan',
          action: 'view',
        },
      },
      {
        title: 'Rate Card',
        icon: 'dollar',
        link: '/app/rate-card',
        index: 'rate-card',
        acl: {
          model: 'rate_card',
          action: 'view',
        },
      },
      {
        title: 'Zipcode Lookup',
        icon: 'map-marker',
        link: '/app/zipcode-lookup',
        index: 'zipcode-lookup',
        acl: {
          model: 'zipcode_lookup',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Ad Ops',
    icon: 'toolbox',
    index: 'adops',
    children: [
      {
        title: 'Order Monitoring',
        icon: 'tools',
        link: '/app/adops/monitoring',
        index: 'order_monitoring',
        acl: {
          model: 'adops',
          action: 'view',
        },
      },
      {
        title: 'Impressions Distribution',
        icon: 'broadcast-tower',
        link: '/app/adops/distribution',
        index: 'inventory_distribution',
        acl: {
          model: 'adops',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Ad Server',
    icon: 'server',
    index: 'delivery',
    label: 'beta',
    children: [
      {
        title: 'Upcoming Campaigns',
        icon: 'calendar-check',
        link: '/app/mediaplan-item-home',
        index: 'mediaplan-items',

        acl: {
          model: 'adserver',
          action: 'view',
        },
      },
      {
        title: 'Orders',
        icon: 'book',
        link: '/app/orders',
        index: 'orders',
        acl: {
          model: 'adserver',
          action: 'view',
        },
      },
      {
        title: 'Line Items',
        icon: 'stream',
        link: '/app/line-items',
        index: 'line-items',
        acl: {
          model: 'adserver',
          action: 'view',
        },
      },
      {
        title: 'Creatives',
        icon: 'video',
        link: '/app/creatives',
        index: 'creatives',
        acl: {
          model: 'adserver',
          action: 'view',
        },
      },
      {
        title: 'AdServer Monitor',
        icon: 'heartbeat',
        link: '/app/adserver-inspector',
        index: 'adserver-inspector',
        acl: {
          model: 'adserver',
          action: 'monitor',
        },
      },
    ],
  },
  {
    title: 'Inventory',
    icon: 'warehouse',
    index: 'inventory',
    children: [
      {
        title: 'Ad Units',
        icon: 'ad',
        link: '/app/inventories',
        index: 'adunits',
        acl: {
          model: 'inventory',
          action: 'view',
        },
      },
      /* {
        title: 'Forecast',
        icon: 'bullseye',
        link: '/app/forecast',
        index: 'forecast',
        acl: {
          model: 'forecast',
          action: 'view',
        },
      }, */
      {
        title: 'Schedulers',
        icon: 'stopwatch',
        link: '/app/schedulers',
        index: 'schedulers',
        acl: {
          model: 'scheduler',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'dollar-sign',
    index: 'Finance',
    children: [
      {
        title: 'Reconciliation',
        icon: 'receipt',
        link: '/app/reconciliations',
        index: 'reconciliations',
        acl: {
          model: 'reconciliation',
          action: 'view',
        },
      },
      {
        title: 'Invoices',
        icon: 'file-invoice-dollar',
        link: '/app/invoices',
        index: 'invoices',
        acl: {
          model: 'invoice',
          action: 'view',
        },
      },
      {
        title: 'Invoice Review',
        icon: 'file-invoice-dollar',
        link: '/app/invoice/review',
        index: 'review_invoice',
        acl: {
          model: 'invoice',
          action: 'review',
        },
      },
      {
        title: 'Memos',
        icon: 'sticky-note',
        link: '/app/credit-memos',
        index: 'credit-memos',
        acl: {
          model: 'memo',
          action: 'view',
        },
      },
      {
        title: 'Journal Entries',
        icon: 'comment-dollar',
        link: '/app/journal-entries',
        index: 'journal-entries',
        acl: {
          model: 'journal',
          action: 'view',
        },
      },
      {
        title: 'Payments',
        icon: 'cash-register',
        link: '/app/payments',
        index: 'payments',
        acl: {
          model: 'payment',
          action: 'view',
        },
      },
      {
        title: 'Expenses',
        icon: 'coins',
        link: '/app/expenses',
        index: 'expenses',
        acl: {
          model: 'expense',
          action: 'view',
        },
      },
      {
        title: 'Commissions',
        icon: 'las la-hand-holding-usd',
        link: '/app/commissions',
        index: 'commissions',
        label: 'beta',
        acl: {
          model: 'commission',
          action: 'view',
        },
      },
      {
        title: 'Commission Rules',
        icon: 'las la-percentage',
        link: '/app/commission-rules',
        index: 'commission-rules',
        label: 'beta',
        acl: {
          model: 'commission',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Admin',
    icon: 'cog',
    index: 'admin',
    children: [
      {
        title: 'System Params',
        icon: 'cogs',
        link: '/app/system-parameters',
        index: 'system-parameters',
        acl: {
          model: 'system_config',
          action: 'view',
        },
      },
      {
        title: 'System Tools',
        icon: 'wrench',
        link: '/app/system-tools',
        index: 'system-tools',
        acl: {
          model: 'system_tools',
          action: 'view',
        },
      },
      {
        title: 'FAQs',
        icon: 'question-circle',
        link: '/app/faq-home',
        index: 'faq',
        acl: {
          model: 'faq',
          action: 'edit',
        },
      },
      {
        title: 'Tasks',
        icon: 'tasks',
        link: '/app/tasks',
        index: 'tasks',
        acl: {
          model: 'task',
          action: 'view',
        },
      },
      {
        title: 'Profiles',
        icon: 'key',
        link: '/app/profiles',
        index: 'profiles',
        acl: {
          model: 'profile',
          action: 'view',
        },
      },
      {
        title: 'Report Views',
        icon: 'object-group',
        link: '/app/views',
        index: 'views',
        acl: {
          model: 'report_view',
          action: 'view',
        },
      },
      {
        title: 'Users',
        icon: 'user',
        link: '/app/users',
        index: 'users',
        acl: {
          model: 'user',
          action: 'view',
        },
      },
      {
        title: 'Terms',
        icon: 'gavel',
        link: '/app/terms',
        index: 'terms',
        acl: {
          model: 'term',
          action: 'view',
        },
      },
      {
        title: 'Billboards',
        icon: 'tv',
        link: '/app/billboards',
        index: 'billboards',
        acl: {
          model: 'billboard',
          action: 'view',
        },
      },
      {
        title: 'Billboard Presets',
        icon: 'swatchbook',
        link: '/app/billboard-presets',
        index: 'billboard-presets',
        acl: {
          model: 'billboard',
          action: 'edit',
        },
      },
      {
        title: 'Audit',
        icon: 'shield',
        link: '/app/audit',
        index: 'audit',
        acl: {
          model: 'audit',
          action: 'view',
        },
      },
    ],
  },
]
